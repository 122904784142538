<div class="dynamic-subtitle">
  <div>Move</div>
  <div><span class="p-name">{{ projectName | shortProjectName }}</span> from
    <span class="p-name">{{ projectName | projectLocation }}</span></div>
  <div>To</div>
  <div>
    @if (project.parent) {
      <span class="p-name">{{ project.parent }}</span>
    } @else {
      <span class="p-holder">Project…</span>
    } </div>
</div>
<form #moveToForm="ngForm" (submit)="send()">
  <mat-form-field class="w-100" appearance="outline" hideRequiredMarker="true" (mousedown)="!isFocused(projectInputRef) && projectInput.value && projectInput.reset(); projectInputRef.blur(); projectInputRef.focus()">
    <mat-label>Destination</mat-label>
    <input matInput type="text" [matAutocomplete]="auto" [(ngModel)]="project.parent" name="projectName" placeholder="Search for project path" #projectInputRef #projectInput="ngModel" smUniquePathValidator [currentName]="projectName | shortProjectName" [existingPaths]="projectsNames" smUniqueNameValidator smInvalidPrefixValidator [invalidPrefix]="projectName" [existingNames]="[projectName | projectLocation]" required minlength="3" (keydown.enter)="projectInput.control.markAsTouched()">
    @if (projectsNames && (projectName !== project.parent) && projectInput.value &&
    (!isAutoCompleteOpen) && !(projectInput.value | stringIncludedInArray:projectsNames)) {
      <span matSuffix class="creat-new-suffix">(Create New)</span>
    }
    <i matSuffix class="al-icon sm-md search-icon" [class]="project.parent? 'al-ico-dialog-x pointer' : 'al-ico-search'" (click)="project.parent && clear(); projectInputRef.focus()" smClickStopPropagation></i>
    @if (projectInput?.errors?.required) {
      <mat-error>Please provide a Project</mat-error>
    }
    @if (projectInput?.errors?.minlength) {
      <mat-error>Please provide a project name longer than 3 characters</mat-error>
    }
    @if (projectInput?.errors?.uniqueName && !projectInput?.errors?.required) {
      <mat-error>Can't move a project to its current location</mat-error>
    }
    @if (projectInput?.errors?.invalidPrefix) {
      <mat-error>Can't move a project to its nested projects</mat-error>
    }
    @if (projectInput?.errors?.uniquePath) {
      <mat-error>{{ projectInput.value }} already contains a project named {{ projectName | shortProjectName }}</mat-error>
    }
    @if (projectInput.touched && projectInput.errors?.emptyName && !projectInput.errors?.minlength) {
      <mat-error>*Project name can't contain only spaces.</mat-error>
    }
    <mat-autocomplete #auto="matAutocomplete" name="ExperimentProject" (opened)="setIsAutoCompleteOpen(true)" (closed)="setIsAutoCompleteOpen(false)" (optionSelected)="locationSelected($event)" class="light-theme" classList="light-theme" autoActiveFirstOption>
      @if (projectsNames !== null && projectInput.value && !(projectInput.value | stringIncludedInArray:projectsNames)) {
        <mat-option class="item" [value]="projectInput.value" (onSelectionChange)="createNewSelected($event)">"{{ projectInput.value }}"
          <span class="new">(Create New)</span></mat-option>
      }
      @for (projectName of projectsNames; track projectName) {
        <mat-option [value]="projectName" [smTooltip]="projectName" smShowTooltipIfEllipsis (onSelectionChange)="optionSelected()">
          <div [smSearchText]="projectInput.value">{{ projectName }}</div>
        </mat-option>
      }
      @if (projectsNames === null) {
        <div class="p-4 pe-none">
          <mat-spinner class="m-auto" [diameter]="32" [strokeWidth]="4" color="accent"></mat-spinner>
        </div>
      }
      <sm-dots-load-more [class.hidden]="!projects || noMoreOptions" (loadMore)="loadMore(projectInput.value)" [loading]="loading"></sm-dots-load-more>
      <mat-option disabled style="height: 0; min-height: 0;"></mat-option> <!-- Empty mat-option, so the autocomplete menu will always pop -->
    </mat-autocomplete>
  </mat-form-field>
</form>
<div class="buttons d-flex justify-content-center">
  <button [disabled]="projectName === project?.parent || moveToForm.invalid" (click)="send()" cdkFocusInitial data-id="MoveButton" class="btn btn-neon yes-button" #moveButton>
    MOVE
  </button>
  <button (click)="closeDialog()" data-id="CancelButton" class="btn btn-outline-neon">
    CANCEL
  </button>

</div>
